@import '../../../../base/scss/variables';

#daily-card {
  margin-bottom: 24px;
  border-radius: $border-radius;

  > :first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  > :last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .label {
    color: #b8b8b8;
    font-family: ClanPro-Bold, sans-serif;
    font-size: 14px;
    text-align: right;
    font-weight: 700;
  }

  .data {
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    color: #201047;
    text-align: left;
  }

  .wrapper {
    background-color: $white;
    flex: 1;
    padding: 24px;

    &.content-list {
      padding: 1px 24px 24px 24px !important;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date {
        color: $purple;
        font-family: ClanPro-Bold, sans-serif;
        font-size: 14px;
      }
    }

    .log {
      display: flex;
      flex-direction: row;
      position: relative;
      min-height: 32px;

      > .data.time {
        font-family: ClanPro-Bold, sans-serif;
        font-weight: 700;
        margin-right: 32px;
        font-size: 12px;
        color: #220c4a;
      }

      .delivered {
        color: $green;
      }

      .default {
        color: $purple;
      }

      .reason {
        color: $red;
        margin: 10px 0 10px 0px;
      }

      .region {
        @extend .reason;
        text-transform: capitalize;
      }

      &:hover {
        span {
          visibility: visible;
          right: 100%;
          top: 50%;
          margin-top: -36px;
          margin-right: 12px;
          z-index: 999;
        }
      }

      span {
        position: absolute;
        width: max-content;
        color: $white;
        background: $purple;
        text-align: left;
        visibility: hidden;
        border-radius: $border-radius;
        padding: 10px;
        font-size: 11px;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 100%;
          margin-top: -8px;
          width: 0;
          height: 0;
          border-left: 8px solid #201047;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
        }

        :first-child {
          font-family: ClanPro-News, sans-serif;
        }

        :last-child {
          font-family: ClanPro-Bold, sans-serif;
        }
      }
    }
  }
}
