@import '../../../../base/scss/variables';

#recipient-panel {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 180px;

  > .notification {
    height: 48px;
    background-color: $yellow;
    position: relative;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12) !important;

    p,
    button {
      font-size: 12px;
      color: darken($yellow, 40%) !important;
    }

    button:hover {
      color: darken($yellow, 25%) !important;
    }

    p {
      margin: 0 10px;
    }
  }

  > .wrapper {
    background-color: $white;
    padding: 27px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    font-family: ClanPro-News, sans-serif;
    font-size: 13px;
    border-radius: $border-radius;
    min-width: 353px;

    .recipient-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .recipient-name {
        color: $purple;
        font-weight: 700;
      }

      .recipient-address {
        color: $purple;
        white-space: pre-wrap;
      }

      .recipient-contact {
        color: $purple;
      }

      .recipient-email {
        color: $purple;
      }
    }

    .actions {
      height: fit-content;
      position: absolute;
      right: 27px;
      top: 44px;
      display: flex;
      flex-direction: column;
    }
  }
  .span-tooltip {
    position: relative;
    display: inline-block;
    width: min-content;
  }
  .span-tooltip .copy-tooltip-success {
    position: fixed;
    font-size: 10px;
    background-color: $purple;
    color: $white;
    border-radius: 5px;
    padding: 4px 8px;
    text-align: center;
    font-family: ClanPro-WideNews, sans-serif;
    display: block;
    visibility: hidden;
    margin-left: -12px;
    margin-top: -16px;
  }
  .visible {
    visibility: visible;
  }

  .edit-recipient-loader {
    position: relative;
    display: flex;
    justify-content: center;
    top: 8px;
    margin-bottom: 14px;
  }

  .edit-recipient-disabled-button {
    display: block;
    position: relative;
    left: 2px;
    height: 40px;
    width: 40px;
    background-color: $light-gray-1;
    border-radius: 4px;

    button {
      pointer-events: none;
      position: relative;
      left: -2px;
      > svg > path {
        fill: $light-gray-4;
      }
    }
  }
}

.tooltip-recipient-change-address {
  top: 6px !important;
  max-width: 110px;
}
