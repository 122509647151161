@import '../../../../base/scss/variables';

#right-panel-container {
  .tabs {
    display: flex;
    font-family: ClanPro-Bold, sans-serif;
    font-size: 14px;
    border-bottom: 2px solid $light-gray-4;
    margin-bottom: 16px;
    height: 40px;
    align-items: center;

    a.tab-active {
      background-color: $white;
      margin-bottom: -1px;
      border-bottom: 4px solid $green !important;
    }

    a {
      color: $purple;
      text-decoration: none;
      padding: 0 12px !important;
      cursor: pointer;
      height: inherit;
      display: flex;
      align-items: center;
    }
  }
}
